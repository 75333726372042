import { inject, injectable } from "inversify";
import { autorun, makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import container from "../../../../stores/ioc";
import { useClassStore } from "../../../../util/useClassStore";
import NavigationStore from "../../../../stores/navigationStore";
import SupabaseGateway from "../../../../gateways/SupabaseGateway";
import UIStore, { AlertType, Modals } from "../../../../stores/uiStore";
import UserStore from "../../../../stores/userStore";
import {
  COURSE_FORM_VALIDATOR,
  ITEMS_MODULE_FORM_VALIDATOR,
  getValidationErrorMessage,
} from "../../../../util/validator";
import CourseStore from "../../../../stores/courseStore";
import { ConfigPanda } from "../../../../panda_video/config";
import QuetionStore from "../../../../stores/quetionStore";
import VideoViewedStore from "../../../../stores/videoViewedStore";
@injectable()
class CoursePresenter {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  @inject(UserStore) private userStore!: UserStore;
  @inject(CourseStore) private courseStore!: CourseStore;
  @inject(QuetionStore) private quetionStore!: QuetionStore;
  @inject(NavigationStore) private navigation!: NavigationStore;
  @inject(VideoViewedStore) private videoViewedStore!: VideoViewedStore;
  course!: ICourse | undefined;
  loadding = false;
  loaddingUploadImage = false;
  imgSrc = "";
  aprendizados: IAprendizados[] = [{ title: "", course: null }];
  moduleForm: IModule[] = [];
  module!: IModule | undefined;
  item_module!: IITEM_MODULE | undefined;
  constructor() {
    makeAutoObservable(this);
    autorun(async () => {
      await this.getCourses();
      await this.getVideoViewedByUser();
    });
  }
  setFormValue = (e: ChangeEvent<HTMLInputElement>) => {
    this.course = { ...this.course, [e.target.name]: e.target.value };
  };
  setFormValueItem = (e: ChangeEvent<HTMLInputElement>) => {
    this.item_module = { ...this.item_module, [e.target.name]: e.target.value };
  };
  setModule = (module: IModule) => {
    this.module = module;
  };
  getIdCategoria = (categoria: string) => {
    switch (categoria) {
      case "desenvolvimeno-programacao":
        return "1";
      case "academico":
        return "2";
      case "administracao-publica":
        return "3";
      case "financa-contabilidade":
        return "4";
      case "produtividade-no-escritorio":
        return "5";
      case "desenvolvimento-pessoal":
        return "6";
      default:
        return "0";
    }
  };
  getTitleCategoria = (categoria: string) => {
    switch (categoria) {
      case "desenvolvimeno-programacao":
        return "Desenvolvimento & Programação";
      case "academico":
        return "Acadêmico";
      case "administracao-publica":
        return "Administração Pública";
      case "financa-contabilidade":
        return "Finanças & contabilidade";
      case "produtividade-no-escritorio":
        return "Produtividade no escritório";
      case "desenvolvimento-pessoal":
        return "Desenvolvimento pessoal";
      default:
        return "0";
    }
  };
  setItemModule = (item_module: IITEM_MODULE) => {
    this.item_module = item_module;
  };
  setImgSrc = (value: string) => {
    this.imgSrc = value;
  };
  setCategoria = (value: string) => {
    this.course = { ...this.course, categoria: value };
  };
  setLevel = (value: string) => {
    this.course = { ...this.course, level: value };
  };
  resetStoreValues = () => {
    this.course = undefined;
  };
  resetStoreItemCourse = () => {
    this.item_module = undefined;
  };
  resetStoreItemModule = () => {
    this.courseStore.resetItemModule();
  };
  resetStoreValuesItemModule = () => {
    this.item_module = undefined;
    this.courseStore.modulos = [];
  };
  resetStoreValuesItemModule1 = () => {
    this.item_module = undefined;
  };
  resetStoreAprendValues = () => {
    this.aprendizados = [{ title: "", course: null }];
  };
  resetStoreModules = () => {
    this.moduleForm = [];
  };
  setCourse = (data: ICourse) => {
    this.course = data;
  };

  uploadImageCurso = async (img: File) => {
    try {
      this.loaddingUploadImage = true;
      await this.courseStore.uploadImage(img, this.course as ICourse);
      this.imgSrc = "";
    } catch (error) {
    } finally {
      this.loaddingUploadImage = false;
    }
  };
  updateCategoria = async (e: string) => {
    try {
      this.course = { ...this.course, categoria: e };
      await this.courseStore.update(this.course);
    } catch (error) {}
  };
  updateItemModule = async () => {
    try {
      this.loadding = true;
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: ConfigPanda.key,
        },
      };
      await fetch(
        `https://api-v2.pandavideo.com.br/videos/${this.item_module?.uuid}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          this.item_module = {
            ...this.item_module,
            video_player: res.video_player,
            video_external_id: res.video_external_id,
            time: this.formatTime(res.length),
          };
        })
        .catch((err) => console.error(err));
      if (
        !this.item_module?.video_external_id &&
        !this.item_module?.video_player
      ) {
        this.ui.showAlert("Id não encontrado .", AlertType.error);
        throw new Error("error.message");
      }
      await ITEMS_MODULE_FORM_VALIDATOR.validate(this.item_module, {
        abortEarly: false,
      });
      await this.courseStore.updateItemModule(this.item_module as IITEM_MODULE);
      this.courseStore.ui.setModal(Modals.EDIT_ITEM_MODULE_MODAL, false);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  updateVisibility = async (e: string) => {
    try {
      this.course = { ...this.course, visibilidade: e };
      await this.courseStore.update(this.course);
    } catch (error) {}
  };

  sliceString = (text: string) => {
    return text.length >= 35 ? text.slice(0, 35) + "..." : text;
  };
  getFormatCurrent = (value: number) => {
    const USDollar = new Intl.NumberFormat();
    return USDollar.format(value);
  };
  parseLocaleNumber = (stringNumber: string, locale: string) => {
    var thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, "");
    var decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, "");
    return parseFloat(
      stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), ".")
    );
  };
  setFormPrice = (e: ChangeEvent<HTMLInputElement>) => {
    this.course = {
      ...this.course,
      [e.target.name]: this.parseLocaleNumber(e.target.value, "usd") as Number,
    };
  };
  update = async () => {
    try {
      this.loadding = true;
      delete this.course?.users;
      await this.courseStore.update(this.course as ICourse);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  getAprendizadosByCourseId = async () => {
    try {
      await this.courseStore.getAprendizadosByCourseId(
        this.course?.id as string
      );
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    }
  };
  getCourseByCategoria = async (id: string | string | undefined) => {
    try {
      this.loadding = true;
      await this.courseStore.getCourseByCategoria(id as string);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  getVideoViewedByUser = async () => {
    try {
      await this.videoViewedStore.getVideoViewedByUser({
        courseId: this.item_module?.course as string,
        userId: this.userStore.user.id as string,
      });
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    }
  };
  getItemModuleByCourseId = async (id: string) => {
    try {
      await this.courseStore.getItemModulesByCourseId(id as string);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    }
  };
  getCourses = async () => {
    try {
      this.loadding = true;
      await this.courseStore.getCourses();
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  getCourseId = async (id: string) => {
    try {
      this.loadding = true;
      const data = await this.courseStore.getCourseById(id);
      this.course = data as ICourse;
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  getItemModuleId = async (id: string) => {
    try {
      this.loadding = true;
      const data = await this.courseStore.getItemModuleById(id);
      this.item_module = data as ICourse;
      await this.quetionStore.getQuetionsByCourseId(
        this.item_module.course as string
      );
      await this.courseStore.getModulesByCourseId(
        this.item_module.course as string
      );
      await this.getVideoViewedByUser();
    } catch (error: any) {
    } finally {
      this.loadding = false;
    }
  };
  getQtdAula = (modulos: IModule[]) => {
    let qtd_aula = 0;
    for (var i = 0; i < modulos.length; i++) {
      const item = modulos[i]?.items_module as IITEM_MODULE[];
      if (item) {
        qtd_aula = item.length + qtd_aula;
      }
    }
    return qtd_aula;
  };

  getModulesByCourseId = async (id: string) => {
    try {
      await this.courseStore.getModulesByCourseId(id as string);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    }
  };
  checkVideoViewed = (item_module: IITEM_MODULE) => {
    const video = this.videoViewedStore.videoViewedList.filter(
      (videoViewed) => videoViewed.item_module === item_module.id
    );
    return video.length > 0 ? true : false;
  };
  createVideoViewed = async (item_module: IITEM_MODULE) => {
    try {
      if (this.checkVideoViewed(item_module)) {
      } else {
        const video: IVideoViewed = {
          course: item_module?.course as string,
          user_id: this.userStore?.user?.id as string,
          item_module: item_module?.id as string,
        };
        await this.videoViewedStore.createVideoViewed(video);
      }
    } catch (error: any) {}
  };
  getPercentVideoViewed = (qtdVideoViwed: number, qtd_aula: number) => {
    return (100 * qtdVideoViwed) / qtd_aula;
  };
  saveAprendizado = async () => {
    try {
      this.loadding = true;
      await this.courseStore.saveAprendizado(this.aprendizados);
      await this.courseStore.getAprendizadosByCourseId(
        this.course?.id as string
      );
      this.resetStoreAprendValues();
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  saveModules = async () => {
    try {
      this.loadding = true;
      let newFormValues = [...this.moduleForm];
      for (var i = 0; i < this.moduleForm.length; i++) {
        if (!this.moduleForm[i].title && this.moduleForm.length > 1) {
          newFormValues.splice(i, 1);
          this.moduleForm = newFormValues;
        }
        if (!this.moduleForm[i].title && this.moduleForm.length == 1)
          throw new Error("Campo título vazio...");
      }
      await this.courseStore.saveModules(this.moduleForm);
      await this.courseStore.getModulesByCourseId(this.course?.id as string);

      this.resetStoreModules();
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    } finally {
      this.loadding = false;
    }
  };

  addCourse = async () => {
    try {
      this.loadding = true;
      delete this.course?.users;
      this.course = { ...this.course, uuid: this.userStore.user?.uuid };
      await COURSE_FORM_VALIDATOR.validate(this.course, { abortEarly: false });
      await this.courseStore.AddCourse(this.course);
      this.resetStoreValues();
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.courseStore.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
      this.courseStore.ui.setModal(Modals.ITEM_MODULE_MODAL, false);
    }
  };
  formatTime = (num: number) => {
    var minutes = Math.floor(num / 60);
    var seconds = Math.floor(num - minutes * 60);

    return (
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  };
  addItemModule = async () => {
    try {
      this.loadding = true;
      this.item_module = {
        ...this.item_module,
        module: this.module?.id,
        course: this.module?.course,
      };
      const arraySplit = this.item_module.link
        ? this.item_module.link.split("?v=", 2)
        : [];
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: ConfigPanda.key,
        },
      };
      await fetch(
        `https://api-v2.pandavideo.com.br/videos/${this.item_module.uuid}`,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          this.item_module = {
            ...this.item_module,
            video_player: res.video_player,
            video_external_id: res.video_external_id,
            time: this.formatTime(res.length),
          };
        })
        .catch((err) => console.error(err));
      await ITEMS_MODULE_FORM_VALIDATOR.validate(this.item_module, {
        abortEarly: false,
      });
      if (
        !this.item_module.video_external_id &&
        !this.item_module.video_player
      ) {
        this.ui.showAlert("Id não encontrado .", AlertType.error);
        throw new Error("error.message");
      }
      await this.courseStore.AddItemModule(this.item_module);
      await this.courseStore.getModulesByCourseId(
        this.module?.course as string
      );
      this.resetStoreValuesItemModule();
      this.courseStore.ui.setModal(Modals.ITEM_MODULE_MODAL, false);
    } catch (error: any) {
      this.ui.showAlert(error, AlertType.error);
    } finally {
      this.loadding = false;
    }
  };
  addFormAprendizado = () => {
    this.aprendizados = [...this.aprendizados, { title: "", course: null }];
  };
  addFormModule = () => {
    this.moduleForm = [...this.moduleForm, { title: "", course: null }];
  };
  removeFormAprendizado = (i: number) => {
    let newFormValues = [...this.aprendizados];
    newFormValues.splice(i, 1);
    this.aprendizados = newFormValues;
  };
  removeFormModule = (i: number) => {
    let newFormValues = [...this.moduleForm];
    newFormValues.splice(i, 1);
    this.moduleForm = newFormValues;
  };

  setValueFormRepeat = (i: number, e: ChangeEvent<HTMLInputElement>) => {
    let newFormValues: any = [...this.aprendizados];
    newFormValues[i][e.target.name] = e.target.value;
    this.aprendizados = newFormValues;
    this.aprendizados[i].course = this.course?.id;
  };
  setValueModuleForm = (i: number, e: ChangeEvent<HTMLInputElement>) => {
    let newFormValues: any = [...this.moduleForm];
    newFormValues[i][e.target.name] = e.target.value;
    this.moduleForm = newFormValues;
    this.moduleForm[i].course = this.course?.id;
  };
  get courseList(): ICourse[] {
    return this.courseStore.courses;
  }
  get courseFilter(): ICourseList[] {
    return this.courseStore.courselist;
  }
  get aprendizadoList(): IAprendizados[] {
    return this.courseStore.aprendizados;
  }
  get videoViewedList(): IVideoViewed[] {
    return this.videoViewedStore.videoViewedList;
  }
  get item_module_list(): IITEM_MODULE[] {
    return this.courseStore.item_module as IITEM_MODULE[];
  }
  get moduleList(): IModule[] {
    return this.courseStore.modulos;
  }
}
export const useCoursePresenter = () =>
  useClassStore<CoursePresenter>(container.get(CoursePresenter));

export default CoursePresenter;
