import React from "react";
import styled from "styled-components";
import { device } from "../../config/devices";

interface ITextInput extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultValue?: string;
  type?: string;
  required?: boolean;
  placeholder?: string;
  width?: number;
  hasLabel?: boolean;
  label?: string;
  icon?: JSX.Element;
}
interface IProps {
  width?: number;
}

export default function TextInput({
  defaultValue = "",
  placeholder,
  width,
  hasLabel,
  label,
  icon,
  ...rest
}: ITextInput) {
  return (
    <Container width={width}>
      <Input defaultValue={defaultValue} placeholder={placeholder} {...rest} />
      {icon && icon}
    </Container>
  );
}

const Group = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 100}%;
`;
const Container = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  height: 30px;
  border: 1px solid #999;
  margin-top: 20px;
  @media ${device.mobileL} {
    padding: 4px 0;
  }
`;

const Input = styled.input`
  border: 0;
  width: 80%;
  font-size: 13px;
  margin-left: 10px;
  font-family: Montserrat;
  outline: none;
`;
