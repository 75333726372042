import { inject, injectable } from "inversify";
import { makeAutoObservable } from "mobx";
import { ChangeEvent } from "react";
import container from "../../../stores/ioc";
import { useClassStore } from "../../../util/useClassStore";
import SupabaseGateway from "../../../gateways/SupabaseGateway";
import UIStore, { AlertType, Modals } from "../../../stores/uiStore";
import UserStore from "../../../stores/userStore";
import QuetionStore from "../../../stores/quetionStore";
import { v4 as uuidv4 } from "uuid";
import {
  ANSWER_FORM_VALIDATOR,
  getValidationErrorMessage,
  QUETION_FORM_VALIDATOR,
} from "../../../util/validator";
import AnswerStore from "../../../stores/answerStore";
import VideoViewedStore from "../../../stores/videoViewedStore";
import CourseStore from "../../../stores/courseStore";
@injectable()
class WatchPresenter {
  [key: string]: string | undefined | any;
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  @inject(UserStore) private userStore!: UserStore;
  @inject(QuetionStore) private quetionStore!: QuetionStore;
  @inject(AnswerStore) private answerStore!: AnswerStore;
  @inject(CourseStore) private course!: AnswerStore;
  loadding = false;
  quetion: IQuetion | undefined;
  answer: IAnswer | undefined;
  constructor() {
    makeAutoObservable(this);
  }
  setFormValue = (e: ChangeEvent<HTMLInputElement>) => {
    this.quetion = {
      ...this.quetion,
      [e.target.name]: e.target.value,
    } as IQuetion;
  };
  setTextareaValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.quetion = {
      ...this.quetion,
      [e.target.name]: e.target.value,
    } as IQuetion;
  };
  setTextareaValueAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.answer = {
      ...this.answer,
      [e.target.name]: e.target.value,
    } as IAnswer;
  };
  setItemModuleQuetion = (id_item_module: string) => {
    this.quetion = { ...this.quetion, item_module: id_item_module } as IQuetion;
  };
  setQuetion = (quetion: IQuetion) => {
    this.quetion = quetion;
  };
  setAnswer = (answer: IAnswer) => {
    this.answer = answer;
  };
  setCourseQuetion = (id_course: string) => {
    this.quetion = { ...this.quetion, course: id_course } as IQuetion;
  };
  resetStoreQuetion = () => {
    this.quetion = undefined;
  };
  resetStoreAnswer = () => {
    this.answer = undefined;
  };
  getAnswerByQuetion = async (id: string) => {
    try {
      this.loadding = true;
      await this.answerStore.getAnswerByQuetion(id);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    } finally {
      this.loadding = false;
    }
  };

  createAnswer = async () => {
    try {
      this.loadding = true;
      if (this.answer) {
        this.answer.external_id = uuidv4();
        this.answer.user_id = this.userStore.user?.id as string;
        this.answer.course = this.quetion?.course as string;
        this.answer.quetion = this.quetion?.id as string;
      }
      await ANSWER_FORM_VALIDATOR.validate(this.answer, {
        abortEarly: false,
      });
      await this.answerStore.createAnswer(this.answer as IAnswer);
      this.ui.setModal(Modals.ADD_ANSWER_MODAL, false);
      this.resetStoreAnswer();
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  createQuetion = async () => {
    try {
      if (this.quetion) {
        this.quetion.external_id = uuidv4();
        this.quetion.user_id = this.userStore.user?.id as string;
      }
      this.loadding = true;
      await QUETION_FORM_VALIDATOR.validate(this.quetion, {
        abortEarly: false,
      });
      await this.quetionStore.createQuetion(this.quetion as IQuetion);
      this.ui.setModal(Modals.ADD_QUETION_MODAL, false);
      this.resetStoreQuetion();
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(
          getValidationErrorMessage(err.path) + err.message,
          AlertType.error
        );
      });
    } finally {
      this.loadding = false;
    }
  };
  updateQuetion = async () => {
    try {
      this.loadding = true;
      await QUETION_FORM_VALIDATOR.validate(this.quetion, {
        abortEarly: false,
      });
      delete this.quetion?.users;
      delete this.quetion?.items_module;
      await this.quetionStore.updateQuetion(this.quetion as IQuetion);
      this.ui.setModal(Modals.EDIT_QUETION_MODAL, false);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(err.message, AlertType.error);
      });
    } finally {
      this.loadding = false;
    }
  };
  updateAnswer = async () => {
    try {
      this.loadding = true;
      await ANSWER_FORM_VALIDATOR.validate(this.answer, {
        abortEarly: false,
      });
      delete this.answer?.users;
      await this.answerStore.updateAnswer(this.answer as IAnswer);
      this.ui.setModal(Modals.EDIT_ANSWER_MODAL, false);
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(err.message, AlertType.error);
      });
    } finally {
      this.loadding = false;
    }
  };
  deleteQuetion = async () => {
    try {
      if (
        window.confirm(
          "Desejas eliminar a presente pergunta e suas respectivas respostas ?"
        )
      ) {
        await this.quetionStore.deleteQuetion(this.quetion as IQuetion);
        await this.answerStore.deleteAnswerByQuetion(
          this.quetion?.id as string
        );
        this.ui.setModal(Modals.EDIT_QUETION_MODAL, false);
      }
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(err.message, AlertType.error);
      });
    } finally {
      this.loadding = false;
    }
  };
  deleteAnswer = async () => {
    try {
      if (window.confirm("Desejas eliminar a presente resposta ?")) {
        await this.answerStore.deleteAnswer(this.answer as IAnswer);
        this.ui.setModal(Modals.EDIT_ANSWER_MODAL, false);
      }
    } catch (error: any) {
      error.inner.forEach((err: any) => {
        this.ui.showAlert(err.message, AlertType.error);
      });
    } finally {
      this.loadding = false;
    }
  };
  get questionList(): IQuetion[] {
    return this.quetionStore.quetions;
  }
  get answerList(): IAnswer[] {
    return this.answerStore.answers;
  }
}
export const useWatchPresenter = () =>
  useClassStore<WatchPresenter>(container.get(WatchPresenter));

export default WatchPresenter;
