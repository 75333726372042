import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals } from "../../stores/uiStore";
import { styled } from "styled-components";
import { Tabs } from "rsuite";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { Paragraph, ParagraphBold } from "../Typography";
import TextInputLarge from "../Inputs/TextInputLarge";
import { MdEdit, MdLink } from "react-icons/md";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import TextArea from "../Inputs/TextArea";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import ButtonBorder from "../Buttons/ButtonBorder";
import theme from "../../theme";
import { FaTrash } from "react-icons/fa6";

const EditQuetionModal = observer(() => {
  const ui = useUiStore();
  const { setModal } = ui;
  const {
    quetion,
    loadding,
    setFormValue,
    deleteQuetion,
    setTextareaValue,
    updateQuetion,
  } = useWatchPresenter();

  return (
    <Modal
      isOpened={ui[Modals.EDIT_QUETION_MODAL]}
      buttonLabel="SALVAR INFORMAÇÕES "
      title="EDITAR PERGUNTA"
      loadding={loadding}
      onClose={() => {
        setModal(Modals.EDIT_QUETION_MODAL, false);
      }}
      onSubmit={updateQuetion}
    >
      <FormWrapper>
        <ParagraphBold>Título ou resumo</ParagraphBold>
        <TextInputLarge
          defaultValue={quetion?.titulo}
          placeholder="Por exemplo : aonde usamos o elemento X para Y ?"
          name="titulo"
          onChange={setFormValue}
          icon={<MdEdit size={24} style={{ padding: 12 }} />}
        />
        <ParagraphBold style={{ marginBottom: 10 }}>
          Detalhes (opcional)
        </ParagraphBold>
        <TextArea
          name="detalhes"
          onChange={setTextareaValue}
          defaultValue={quetion?.detalhes}
          placeholder="Exemplo: No minuto 04:34 tive dúvidas relacionada com ..."
        />
        <ButtonBorder
          color={theme.BACKGROUND.RED_THEME}
          icon={<FaTrash />}
          label="ELIMINAR PERGUNTA"
          onClick={deleteQuetion}
        />
      </FormWrapper>
    </Modal>
  );
});
export default EditQuetionModal;
const FormWrapper = styled.div`
  min-height: 250px;
  padding: 10px;
`;
