import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";

import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { Drawers as Drawer, AlertType } from "./uiStore";
import { uuid } from "uuidv4";
interface IGetVideoViewed {
  courseId?: string;
  userId?: string;
}
@injectable()
class VideoViewedStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  videoViewedList!: IVideoViewed[];
  constructor() {
    makeAutoObservable(this);
  }
  createVideoViewed = async (video?: IVideoViewed) => {
    try {
      const { data, error } =
        await this.supabaseGateway.insertToTable<IVideoViewed>(
          Tables.video_viewed,
          video as IVideoViewed
        );
      this.ui.showAlert(error.message, AlertType.error);
      if (error) throw new Error(error.message);
      this.getVideoViewedByUser({
        courseId: video?.course,
        userId: video?.user_id,
      });
    } catch (error) {}
  };
  getVideoViewedByUser = async (data1: IGetVideoViewed) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilterColumns(
          Tables.video_viewed,
          `*`,
          {
            column1: "course",
            operator: "eq",
            value1: data1.courseId as string,
            column2: "user_id",
            value2: data1.userId as string,
          }
        );
      runInAction(() => {
        this.videoViewedList = data as IVideoViewed[] | [];
      });
      if (error) throw new Error(error.message);
    } catch (error: any) {}
  };
}
export const useVideoViewed = () =>
  useClassStore<VideoViewedStore>(container.get(VideoViewedStore));

export default VideoViewedStore;
