import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals } from "../../stores/uiStore";
import { styled } from "styled-components";
import { Paragraph, ParagraphBold } from "../Typography";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import Loadding from "../loadding/page";
import AnswerList from "../list/AnswerList";
const AnswerListModal = observer(() => {
  const ui = useUiStore();
  const { setModal } = ui;
  const { loadding, quetion, answerList, setQuetion, resetStoreAnswer } =
    useWatchPresenter();
  return (
    <Modal
      isOpened={ui[Modals.ANSWER_LIST_MODAL]}
      buttonLabel="ENVIAR RESPOSTA"
      title={quetion?.titulo}
      size="lg"
      loadding={loadding}
      onClose={() => {
        setModal(Modals.ANSWER_LIST_MODAL, false);
        setModal(Modals.QUETION_LIST_MODAL, true);
      }}
      onSubmit={() => {
        resetStoreAnswer();
        setModal(Modals.ADD_ANSWER_MODAL, true);
      }}
    >
      <FormWrapper>
        <Paragraph size={"13px"}>{quetion?.detalhes}</Paragraph>
        <hr />
        <Paragraph
          size="12px"
          style={{
            marginRight: 5,
          }}
        >
          {quetion?.created_at &&
            `Publicado em: ${format(
              new Date(quetion?.created_at as Date),
              "dd, MMMM yyyy",
              {
                locale: pt,
              }
            )}`}
          <Link
            to={""}
            style={{
              padding: "2px 4px",
              fontSize: 13,
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <strong>{quetion?.users?.name}</strong>
          </Link>
        </Paragraph>
        <hr />
        {loadding ? (
          <>
            <Loadding />
          </>
        ) : (
          <>
            <AnswerList answers={answerList} />
          </>
        )}
      </FormWrapper>
    </Modal>
  );
});
export default AnswerListModal;
const FormWrapper = styled.div`
  min-height: 40px;
`;
