import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals as Modal1 } from "../../stores/uiStore";
import { Modals } from "..";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import ListQuetion from "../list/ListQuetionsLesson";
import styled from "styled-components";
import { Form } from "react-router-dom";

const QuetionListModal: React.FC = observer(() => {
  const ui = useUiStore();
  const { setModal } = ui;
  const { setCourseQuetion, setItemModuleQuetion, questionList } =
    useWatchPresenter();
  return (
    <>
      <Modal
        isOpened={ui[Modal1.QUETION_LIST_MODAL]}
        buttonLabel="ENVIAR PERGUNTA"
        title="Perguntas & respostas"
        size="lg"
        onClose={() => {
          setModal(Modal1.QUETION_LIST_MODAL, false);
        }}
        onSubmit={() => {
          setModal(Modal1.ADD_QUETION_MODAL, true);
        }}
      >
        <FormWrapper>
          {questionList && <ListQuetion quetions={questionList} />}
        </FormWrapper>
      </Modal>
      <Modals.AddQuetionModal />
      <Modals.EditQuetionModal />
      <Modals.AnswerListModal />
      <Modals.AddAnswerModal />
      <Modals.EditAnswerModal />
    </>
  );
});
export default QuetionListModal;
const FormWrapper = styled.div`
  min-height: 150px;
  padding: 10px;
`;
