import { Drawer as RSDrawer } from "rsuite";
import { TypeAttributes } from "rsuite/esm/internals/types";
type IComponentProps = {
  onClose?: () => void;
  isOpened?: boolean;
  onSubmit?: () => void;
  title?: string;
  buttonLabel?: string;
  size?: TypeAttributes.Size;
  children: React.ReactNode;
  loadding?: boolean;
};

const Drawer: React.FC<IComponentProps> = ({
  onClose,
  onSubmit,
  isOpened,
  title,
  children,
  buttonLabel,
  loadding = false,
  size,
}) => {
  return (
    <RSDrawer open={isOpened} placement="right" onClose={onClose}>
      <RSDrawer.Body>{children}</RSDrawer.Body>
    </RSDrawer>
  );
};
export default Drawer;
