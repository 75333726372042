import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { AlertType } from "./uiStore";
@injectable()
class QuetionStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  quetions!: IQuetion[];
  quetionSaved = false;
  constructor() {
    makeAutoObservable(this);
  }
  resetQuetionSaved = () => {
    this.quetionSaved = false;
  };
  createQuetion = async (quetion: IQuetion) => {
    try {
      const { data, error } =
        await this.supabaseGateway.insertToTable<IQuetion>(
          Tables.quetions,
          quetion
        );
      runInAction(() => {
        this.quetionSaved = true;
      });
      if (error) throw new Error(error.message);
      this.getQuetionsByCourseId(quetion?.course as string);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  deleteQuetion = async (quetion: IQuetion) => {
    try {
      await this.getQuetionsByCourseId(quetion?.course as string);
      const { error } = await this.supabaseGateway.deleteFromTable(
        Tables.answers,
        "id",
        quetion?.id as string
      );
      this.getQuetionsByCourseId(quetion?.course as string);
      if (error) throw new Error(error.message);

      this.ui.showAlert("Resposta eliminada com sucesso...", AlertType.success);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  updateQuetion = async (quetion: IQuetion) => {
    try {
      const { data, error } = await this.supabaseGateway.updateTable<IQuetion>(
        Tables.quetions,
        quetion,
        { id: quetion.id }
      );
      this.getQuetionsByCourseId(quetion?.course as string);
      runInAction(() => {
        this.quetionSaved = true;
      });
      if (error) throw new Error(error.message);
      this.ui.showAlert(
        "Informações guardadas com sucesso...",
        AlertType.success
      );
      return data;
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  getQuetionsByCourseId = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.quetions,
          `*, users(*), items_module(*)`,
          {
            column: "course",
            operator: "eq",
            value: id,
          }
        );
      runInAction(() => {
        this.quetions = data as IQuetion[] | [];
      });
      if (error) throw new Error(error.message);
      return data;
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
}
export const useCourseStore = () =>
  useClassStore<QuetionStore>(container.get(QuetionStore));

export default QuetionStore;
