import { Avatar, Col, FlexboxGrid, HStack, List, Row } from "rsuite";
import { Paragraph, ParagraphBold } from "../Typography";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import { useUiStore, Modals } from "../../stores/uiStore";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import { useUserStore } from "../../stores/userStore";
interface AnswerListProps {
  answers: IAnswer[];
}
const AnswerList = observer(({ answers }: AnswerListProps) => {
  const ui = useUiStore();
  const { setModal } = ui;
  const { setAnswer } = useWatchPresenter();
  const { user } = useUserStore();
  return (
    <List style={{ marginTop: 10, marginLeft: 30 }}>
      {answers.map((answer) => (
        <List.Item key={answer.id}>
          <HStack spacing={15} alignItems="center">
            <Avatar
              style={{
                width: 40,
                height: 40,
              }}
              src={answer?.users?.img_url || ""}
            />
            <div style={{ width: "100%" }}>
              <ParagraphBold size="13px" color="#111">
                {answer.users && answer.users.name}
              </ParagraphBold>
              <Paragraph
                size="13px"
                color="#555"
                style={{
                  marginTop: 2,
                }}
              >
                {answer?.created_at &&
                  ` ${format(
                    new Date(answer?.created_at as Date),
                    "dd, MMMM yyyy",
                    {
                      locale: pt,
                    }
                  )}`}

                {user && (
                  <>
                    {user?.id == answer.user_id && (
                      <Link
                        to={"#"}
                        onClick={() => {
                          setModal(Modals.EDIT_ANSWER_MODAL, true);
                          setAnswer(answer);
                        }}
                      >
                        <strong style={{ marginLeft: 10 }}>Editar</strong>
                      </Link>
                    )}
                  </>
                )}
              </Paragraph>
              <Paragraph size="14px" color="#000" style={{ marginTop: 0 }}>
                {answer.detalhes}
              </Paragraph>
            </div>
          </HStack>
        </List.Item>
      ))}
    </List>
  );
});
export default AnswerList;
