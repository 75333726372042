import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { AlertType } from "./uiStore";
@injectable()
class AnswerStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  answers!: IAnswer[];
  answerSaved = false;
  constructor() {
    makeAutoObservable(this);
  }
  createAnswer = async (answer: IAnswer) => {
    try {
      const { data, error } = await this.supabaseGateway.insertToTable<IAnswer>(
        Tables.answers,
        answer
      );
      runInAction(() => {
        this.answerSaved = true;
      });
      if (error) throw new Error(error.message);
      this.getAnswerByQuetion(answer?.quetion as string);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  getAnswerByQuetion = async (id: string) => {
    try {
      const { data, error } =
        await this.supabaseGateway.selectFromTableWithFilter(
          Tables.answers,
          `*, users(*)`,
          {
            column: "quetion",
            operator: "eq",
            value: id,
          }
        );
      runInAction(() => {
        this.answers = data as IAnswer[] | [];
      });
      if (error) throw new Error(error.message);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  updateAnswer = async (answer: IAnswer) => {
    try {
      const { data, error } = await this.supabaseGateway.updateTable<IAnswer>(
        Tables.answers,
        answer,
        { id: answer.id }
      );
      this.getAnswerByQuetion(answer.quetion as string);
      runInAction(() => {
        this.answerSaved = true;
      });
      if (error) throw new Error(error.message);
      this.ui.showAlert(
        "Informações guardadas com sucesso...",
        AlertType.success
      );
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  deleteAnswerByQuetion = async (quetion: string) => {
    try {
      const { error } = await this.supabaseGateway.deleteFromTable(
        Tables.answers,
        "quetion",
        quetion as string
      );

      if (error) throw new Error(error.message);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  deleteAnswer = async (answer: IAnswer) => {
    try {
      const { error } = await this.supabaseGateway.deleteFromTable(
        Tables.answers,
        "id",
        answer?.id as string
      );

      if (error) throw new Error(error.message);

      await this.getAnswerByQuetion(answer?.quetion as string);
      this.ui.showAlert("Resposta eliminada com sucesso...", AlertType.success);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
}
export const useAnswerStore = () =>
  useClassStore<AnswerStore>(container.get(AnswerStore));

export default AnswerStore;
