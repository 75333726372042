import { Avatar, Col, FlexboxGrid, HStack, List, Row } from "rsuite";
import { Paragraph, ParagraphBold } from "../Typography";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import { useUiStore, Modals } from "../../stores/uiStore";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
interface ListQuetionProps {
  quetions: IQuetion[];
}
const ListQuetion = observer(({ quetions }: ListQuetionProps) => {
  const ui = useUiStore();
  const { setModal } = ui;
  const { setQuetion, getAnswerByQuetion } = useWatchPresenter();
  return (
    <List style={{ marginTop: 20 }}>
      {quetions.map((quetion) => (
        <List.Item key={quetion.id}>
          <HStack spacing={15} alignItems="center">
            <Avatar
              style={{
                width: 40,
                height: 40,
              }}
              src={quetion?.users?.img_url || ""}
            />
            <div style={{ width: "100%" }}>
              <ParagraphBold size="14px" color="#000" style={{ marginTop: 0 }}>
                {quetion.titulo}
              </ParagraphBold>

              <Paragraph size="14px" color="#000" style={{ marginTop: 0 }}>
                {quetion.detalhes}
              </Paragraph>
              <Paragraph
                size="12px"
                color="#666"
                style={{ marginTop: 0, marginBottom: 10 }}
              ></Paragraph>
              <hr style={{ margin: 0, padding: 0, paddingBottom: 10 }} />
              <Paragraph
                size="12px"
                style={{
                  marginRight: 5,
                }}
              >
                {quetion.users && (
                  <Link
                    to={""}
                    style={{
                      padding: "2px 4px",
                      fontSize: 13,
                      marginRight: 10,
                    }}
                  >
                    <strong>{quetion.users.name}</strong>
                  </Link>
                )}
                <Link
                  to={"#"}
                  onClick={() => {
                    setModal(Modals.QUETION_LIST_MODAL, false);
                    setModal(Modals.ANSWER_LIST_MODAL, true);
                    setQuetion(quetion);
                    getAnswerByQuetion(quetion.id as string);
                  }}
                  style={{
                    color: "#444",
                    fontSize: 13,
                    marginRight: 10,
                    fontFamily: "Montserrat",
                  }}
                >
                  <strong>Responder</strong>
                </Link>
                <Link
                  to={"#"}
                  onClick={() => {
                    setModal(Modals.EDIT_QUETION_MODAL, true);
                    setQuetion(quetion);
                  }}
                  style={{
                    color: "#444",
                    fontSize: 13,
                    marginRight: 10,
                    fontFamily: "Montserrat",
                  }}
                >
                  <strong>Editar</strong>
                </Link>
                {`Publicado em: ${format(
                  new Date(quetion.created_at as Date),
                  "dd, MMMM yyyy",
                  {
                    locale: pt,
                  }
                )}`}
              </Paragraph>
            </div>
          </HStack>
        </List.Item>
      ))}
    </List>
  );
});
export default ListQuetion;
