import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals } from "../../stores/uiStore";
import { styled } from "styled-components";
import { Tabs } from "rsuite";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { Paragraph, ParagraphBold } from "../Typography";
import TextInputLarge from "../Inputs/TextInputLarge";
import { MdEdit, MdLink } from "react-icons/md";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import TextArea from "../Inputs/TextArea";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";

const AddAnswerModal = observer(() => {
  const ui = useUiStore();
  const { setModal } = ui;
  const { loadding, setFormValue, setTextareaValueAnswer, createAnswer } =
    useWatchPresenter();
  return (
    <Modal
      isOpened={ui[Modals.ADD_ANSWER_MODAL]}
      buttonLabel="ENVIAR"
      title="ENVIAR UMA RESPOSTA"
      loadding={loadding}
      onClose={() => {
        setModal(Modals.ADD_ANSWER_MODAL, false);
      }}
      onSubmit={createAnswer}
    >
      <FormWrapper>
        <ParagraphBold style={{ marginBottom: 10 }}>
          Detalhes (opcional)
        </ParagraphBold>
        <TextArea
          label="opcional"
          name="detalhes"
          onChange={setTextareaValueAnswer}
          placeholder="Descreve o máximo possível da sua resposta..."
        />
      </FormWrapper>
    </Modal>
  );
});
export default AddAnswerModal;
const FormWrapper = styled.div`
  min-height: 140px;
  padding: 10px;
`;
