import { injectable } from "inversify";
import { makeAutoObservable } from "mobx";
import { toast, ToastOptions } from "react-toastify";
import { css } from "styled-components";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";

export enum AlertType {
  success,
  info,
  error,
}

export enum Drawers {
  LOGIN_DRAWER = "loginDrawer",
  SIGNUP_DRAWER = "signupDrawer",
  MENU_MOBILE_DRAWER = "menuMobileDrawer",
  PAYMENT_COURSE_DRAWER = "paymentCourseDrawer",
  GRADE_CURRICULAR_DRAWER = "gradeCurricularDrawer",
}
export enum Modals {
  ITEM_MODULE_MODAL = "itemModuleModal",
  EDIT_ITEM_MODULE_MODAL = "editItemModuleModal",
  ADD_QUETION_MODAL = "addQuetionModal",
  EDIT_QUETION_MODAL = "editQuetionModal",
  QUETION_LIST_MODAL = "quetionListModal",
  ADD_ANSWER_MODAL = "addAsnwerModal",
  ANSWER_LIST_MODAL = "AsnwerListModal",
  EDIT_ANSWER_MODAL = "EditAsnwerModal",
  INFO_COURSE_MODAL = "infoCourseModal",
}

const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: css({
    marginTop: "50px",
    background: "#fffff",
  }),
} as unknown as ToastOptions;

@injectable()
class UIStore {
  [key: string]: string | undefined | any;
  [Modals.ITEM_MODULE_MODAL] = false;
  [Modals.EDIT_ITEM_MODULE_MODAL] = false;
  [Modals.ADD_QUETION_MODAL] = false;
  [Modals.ADD_ANSWER_MODAL] = false;
  [Modals.EDIT_ANSWER_MODAL] = false;
  [Modals.EDIT_QUETION_MODAL] = false;
  [Modals.QUETION_LIST_MODAL] = false;
  [Modals.ANSWER_LIST_MODAL] = false;
  [Modals.INFO_COURSE_MODAL] = false;
  [Drawers.LOGIN_DRAWER] = false;
  [Drawers.SIGNUP_DRAWER] = false;
  [Drawers.MENU_MOBILE_DRAWER] = false;
  [Drawers.PAYMENT_COURSE_DRAWER] = false;
  [Drawers.GRADE_CURRICULAR_DRAWER] = false;

  constructor() {
    makeAutoObservable(this);
  }

  setModal = (modal: string, value: boolean) => {
    this[modal] = value;
  };
  setDrawer = (drawer: string, value: boolean) => {
    this[drawer] = value;
  };

  showAlert(message: string, notificationType: AlertType) {
    switch (notificationType) {
      case AlertType.success:
        return toast.success(message, toastConfig);
      case AlertType.info:
        return toast.info(message, toastConfig);
      case AlertType.error:
        return toast.error(message, toastConfig);

      default:
        break;
    }
  }
}

export const useUiStore = () => useClassStore<UIStore>(container.get(UIStore));

export default UIStore;
